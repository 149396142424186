// CSS Style
import 'styles/site';
import 'styles/style.min';
import 'styles/public';

// Other JS
import 'admin/js/notification';
import('admin/js/site');

// 因為在 webpack 打包的時候，沒有瀏覽器的 window 物件，所以必須 assign 這樣才能在 rails view 使用
// Ref: https://tinyurl.com/ycrtavmd
window.jQuery = $;
window.$ = $;
